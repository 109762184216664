import css from "./appCheckbox.module.scss";
import React from "react";

// Represents css classes, add/remove/rename things both here and in css file
export const checkboxVariants = {
    default: 'default',
    greenBgOnCheck: 'greenBgOnCheck',
    redBgOnCheck: 'redBgOnCheck',
}

const AppCheckbox = (
    {
        label, // text label after checkbox
        error = false,
        disabled = false,
        variant = checkboxVariants.redBgOnCheck,
        className = '',
        wrapperStyle,
        labelStyle,
        indicatorStyle,
        tags = [],
        style, // same thing as indicatorStyle for convenience
        errorField, // i.e. errorField={<>This message wll pop-up when error is true!</>}
        ...htmlCheckboxAttributes
    }) => {

    const indicatorStyles = {...style, ...indicatorStyle}
    const invalidClass = error ? css.invalid : '';
    const disabledClass = disabled ? css.checkboxDisabled : '';

    return (
        <div
            style={wrapperStyle}
            className={[className, css.checkboxWrapper, disabledClass, invalidClass, css[variant]].join(' ')}
        >
            <label
                style={labelStyle}
                className={css.control}>
                {label}
                <input
                    type="checkbox"
                    disabled={disabled}
                    {...htmlCheckboxAttributes}
                />
                <div
                    style={indicatorStyles}
                    className={css.controlIndicator}
                />
            </label>
            {(!!errorField && error) ? errorField : null}
        </div>
    )
}

export default AppCheckbox