import React from "react";
import css from './menuDropdownArrows.module.scss';

const MenuDropdownArrows = props => {
    const { opened = false } = props;

    return (
        <span className={css.arrows}>
            {opened &&
            <span className={css.arrow}>
                <div className={[css.arrowText, css.arrowUpText].join(' ')}>&lsaquo;</div>
            </span>
            }
            {!opened &&
            <span className={css.arrow}>
                <div className={[css.arrowText, css.arrowDownText].join(' ')}>&rsaquo;</div>
            </span>
            }
        </span>
    );
};

export default MenuDropdownArrows;
