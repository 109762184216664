import React from "react";
import css from './menuDropdown.module.scss';

const MenuDropdown = props => {
    const {
        opened = false,
        children,
        mobile = false,
    } = props;

    const openedClass = opened ? css.opened : null;
    const mobileClass = mobile ? css.mobile : '';

    return (
        <div className={[css.wrapper, mobileClass, openedClass].join(' ')}>
            {children}
        </div>
    )
}

export default MenuDropdown;