import {Link} from 'gatsby';
import React from 'react';
import css from './deeplaiButton.module.scss';
import LogoWhite from "../../../images/logos/Navbar_Logo_Slogan.svg";
import LogoBlack from "../../../images/logos/Navbar_Logo_Slogan.svg";
import {getLink, getRoutes} from "../routes";
import {useTranslation} from "react-i18next";

const DeeplaiButton = props => {
    const {t} = useTranslation();
    const {
        height = '24px',
        version = 'black'
    } = props;

    const logoAlt = "deeplai logo logotype";
    const link = getLink([getRoutes().home]);

    return (
        <Link to={link} className={css.link}>
            {version === 'white' && <img src={LogoWhite} alt={logoAlt} style={{height}}/>}
            {version === 'black' && <img src={LogoBlack} alt={logoAlt} style={{height}}/>}
        </Link>
    );
};

export default DeeplaiButton;
