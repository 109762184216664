import React, {useEffect, useState} from "react";
import {breakpoints, viewports} from "../componentsUtils";

function useBreakpoint() {
  const isClient = typeof window === 'object';

  const getWindowWidth = () => {
    return isClient ? window.innerWidth : undefined;
  };

  const breakpointNames = Object.keys(breakpoints);

  const widestBreakpointName = 'full';

  const [breakpointName, setBreakpointName] = useState(widestBreakpointName);

  const checkBreakpoint = (width, index) => {
    if (index === breakpointNames.length) { // out of range, so wider than last breakpoint
      return widestBreakpointName;
    }

    const breakpointName = breakpointNames[index];
    const breakpointWidth = breakpoints[breakpointName];

    if (width > breakpointWidth ) {
      return checkBreakpoint(width, index + 1);
    }

    return breakpointName;
  };

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      const currentBreakpoint = checkBreakpoint(getWindowWidth(), 0);
      setBreakpointName(currentBreakpoint);
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getBreakpointWidth = () => {
    return breakpoints[breakpointName] || 999999; // integer greater than last breakpoint
  };

  const getViewport = width => {
    if (width <= breakpoints.sm) {
      return viewports.phoneViewport;
    }
    if (width <= breakpoints.lg) {
      return viewports.tabletViewport;
    }
    return viewports.desktopViewport;
  };

  const getBreakpointData = () => {
    const width = getBreakpointWidth();
    const viewport = getViewport(width);
    return {
      name: breakpointName,
      width,
      viewport
    }
  };

  return getBreakpointData();
}


export default useBreakpoint;
