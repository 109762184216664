import {graphql, useStaticQuery} from 'gatsby';
import React, {useEffect, useState} from 'react';
import {getRoutes} from '../routes';
import {breakpoints} from "../../componentsUtils";
import useBreakpoint from "../../hooks/useBreakpoint";
import css from './header.module.scss';
import {useTranslation} from 'react-i18next';
import HeaderLink from '../HeaderLink/HeaderLink.js';
import DeeplaiButton from "../DeeplaiButton/DeeplaiButton";
import UE from "../../../images/common/EU_navbar.svg";
import TimberIdLogo from "../../../images/logos/Timber_ID_logo.svg";
import SignUpButton from "../SignUpButton/SignUpButton";

let Header;
Header = () => {
    const {site} = useStaticQuery(
        graphql`
  query {
    site {
      siteMetadata {
        optiplusWebAppUrl
        webAppSignInEnabled
        quickPortals {
          webPublishing
          cars
          realEstate
          restoration
        }
      }
    }
  }
`
    );

    const {t} = useTranslation();

    const breakpoint = useBreakpoint();
    const mobile = breakpoint.width <= breakpoints.lg;

    const mobileClass = mobile ? css.mobile : '';

    const [open, setOpen] = useState(false);

    const openClass = open ? css.open : '';

    const toggleMenu = () => {
        if (mobile) {
            setOpen(!open);
        }
    };

    const closeMenu = () => {
        setOpen(false);
    };

    const [signInOtherOpen, setSignInOtherOpen] = useState(false);


    const toggleHtmlNodeScroll = () => {
        const htmlNode = document.body.parentElement;
        const scrollbarWidth = window.innerWidth - document.body.clientWidth;
        if (open) {
            htmlNode.style.paddingRight = `${scrollbarWidth}px`;
            htmlNode.style.overflow = 'hidden';
        } else {
            htmlNode.style.paddingRight = '0px';
            htmlNode.style.overflow = null;
        }
    };

    useEffect(() => {
        toggleHtmlNodeScroll();
    }, [open]);

    useEffect(() => {
        closeMenu();
    }, [mobile]);


    const renderMenu = (menu, menuLevel) => {
        return menu.map((item, index) => {
            const {
                key = `menu_${menuLevel || 0}_${index}`,
                page,
                externalLink,
                customItemRender,
                borderedAndGreen,
                isDropdown = false,
                isHeaderMobileMenuSeparator = false,
                label,
                labelHidden,
                submenu = [],
                renderCustomSubmenuFn,
                setActiveForSubpages
            } = item;
            const submenuLevel = isDropdown ? -1 : menuLevel + 1;
            const submenuToRender = submenu.length ? renderMenu(submenu, submenuLevel) : [];
            return (
                <HeaderLink
                    key={key}
                    subpage={page}
                    externalLink={externalLink}
                    customItemRender={customItemRender}
                    borderedAndGreen={borderedAndGreen}
                    isDropdown={isDropdown}
                    isHeaderMobileMenuSeparator={isHeaderMobileMenuSeparator}
                    text={label}
                    textHidden={labelHidden}
                    // separator
                    mobile={mobile}
                    onClick={() => {
                        if (!isDropdown) {
                            closeMenu();
                        }
                    }}
                    submenu={submenuToRender}
                    renderCustomSubmenuFn={renderCustomSubmenuFn}
                    menuLevel={menuLevel}
                    setActiveForSubpages={setActiveForSubpages}
                />
            );
        });
    };

    const routes = getRoutes();

    const mainMenu = [{
        page: [routes.home],
        label: "Home",
    }, {
        page: [routes.Traceability],
        label: routes.Traceability.pageTitle,
    }, {
        page: [routes.Technology],
        label: routes.Technology.pageTitle,
    }, {
        page: [routes.Platform],
        label: routes.Platform.pageTitle,
    }, {
        page: [routes.Offering],
        label: routes.Offering.pageTitle,
    }];

    const eudrMenu = [{
        page: [routes.Eudr],
        label: routes.Eudr.pageTitle
    }]

    const companySubmenu = [{
        page: [routes.About],
        label: routes.About.pageTitle,
        submenu: []
    }, {
        page: [routes.news],
        label: routes.news.pageTitle,
        submenu: []
    }, {
        page: [routes.Partnership],
        label: routes.Partnership.pageTitle,
        submenu: []
    }, {
        page: [routes.Careers],
        label: routes.Careers.pageTitle,
        submenu: []
    }, {
        page: [routes.LegalDocuments],
        label: routes.LegalDocuments.pageTitle,
        submenu: []
    }, {
        page: [routes.EuropeanFunds],
        label: routes.EuropeanFunds.pageTitle,
        submenu: []
    }, {
        page: [routes.Contact],
        label: routes.Contact.pageTitle,
        submenu: []
    }];
    if (mobile) {
        // mainMenu.push({
        //     key: 'menu_company_mobile_header',
        //     isHeaderMobileMenuSeparator: true,
        //     label: "Company",
        // });
        mainMenu.push(...companySubmenu);
    } else {
        mainMenu.push({
            key: 'menu_company_dropdown',
            isDropdown: true,
            label: "Company",
            submenu: companySubmenu,
            setActiveForSubpages: companySubmenu.map(item => item.page),
        });
    }
    mainMenu.push(...eudrMenu);
    // like Careers:
    // mainMenu.push({
    //     page: [routes.news],
    //     label: "Company",
    //     submenu: companySubmenu,
    //     setActiveForSubpages: companySubmenu.map(item => item.page),
    // });


// ------------------ sample dropdown --------------------

    /*
        const menuSampleDropdownItems = [];
        menuSampleDropdownItems.push({
            page: [routes.Careers],
            label: "D/Careers",
        });
        menuSampleDropdownItems.push({
            page: [routes.Partnership],
            label: "D/Partnership",
        });
        if (mobile) {
            mainMenu.push(...menuSampleDropdownItems);
        } else {
            mainMenu.push({
                key: 'menu_sample_dropdown',
                isDropdown: true,
                label: "Dropdown",
                submenu: [...menuSampleDropdownItems],
                setActiveForSubpages: menuSampleDropdownItems.map(item => item.page),
            });
        }
    */

// ------------------ sample submenu --------------------
    /*
    const sampleSubmenu = [{
        page: [routes.CareersPythonDeveloper],
        label: "Python",
        submenu: [{
            page: [routes.CareersPythonDeveloper],
            label: "Python",
            submenu: []
        }, {
            page: [routes.CareersDevOps],
            label: "DevOps",
            submenu: []
        }, {
            page: [routes.CareersDigitalMarketingSpecialist],
            label: "Marketing",
            submenu: []
        }]
    }, {
        page: [routes.CareersDevOps],
        label: "DevOps",
        submenu: []
    }, {
        page: [routes.CareersDigitalMarketingSpecialist],
        label: "Marketing",
        submenu: []
    }];
    const renderSolutionMobileMainLink = (label, logo, logoStyle = {}) => {
        return (
            <div className={css.solutionsMobileLink}>
                {label}
                <img src={logo} alt="" style={logoStyle}/>
            </div>
        );
    };
    mainMenu.push({
        page: [routes.Careers],
        label: renderSolutionMobileMainLink(<app-nobreak>Careers
            <app-plus>+</app-plus>
        </app-nobreak>, DcodeLogo),
        labelHidden: !mobile,
        submenu: sampleSubmenu
    });
    */
// ------------------ sample banner submenu --------------------

    /*
        const quickPortalsUrls = {
            webPublishing: 'https://publishing.deeplai.com/',
            cars: 'https://cars.deeplai.com/',
            realEstate: 'https://estate.deeplai.com/',
            restoration: 'https://aged.deeplai.com/'
        };
        const fastTrackMenuItems = [{
            logo: WebPublishingLogo,
            url: quickPortalsUrls.webPublishing,
            mobileLabel: "Web Publishing",
            keyRender: 'fast_track_web_publishing'
        }, {
            logo: CarsLogo,
            url: quickPortalsUrls.cars,
            mobileLabel: "Cars",
            keyRender: 'fast_track_cars'
        }, {
            logo: RealEstateLogo,
            url: quickPortalsUrls.realEstate,
            mobileLabel: "Real Estate",
            keyRender: 'fast_track_real_estate'
        }, {
            logo: RestorationLogo,
            url: quickPortalsUrls.restoration,
            mobileLabel: "Aged Photos",
            keyRender: 'fast_track_restoration'
        }];
        const renderFastTrackDesktopMenuItem = item => {
            const {logo, url, keyRender} = item;
            return (
                <a href={url} target="_blank" className={css.fastTrackMenuItem} key={keyRender}>
                    <div className={css.logoWrapper}>
                        <img src={logo} alt=""/>
                    </div>
                    <MainButton
                        label="Learn more"
                        isLink={false}
                        className={css.solutionsSubmenuButton}
                    />
                </a>
            );
        };
        const renderFastTrackDesktopMenuItems = () => {
            return fastTrackMenuItems.map(item => renderFastTrackDesktopMenuItem(item))
        };
        const renderFastTrackDesktopMenu = () => {
            return (
                <div className={css.fastTrack}>
                    <div className={css.labelWrapper}>
                        <img src={FastTrackLogo} alt=""/>
                    </div>
                    {renderFastTrackDesktopMenuItems()}
                </div>

            );
        };
        const addFastTrackItemsToMobileMenu = () => {
            fastTrackMenuItems.forEach(item => {
                const {logo, url, keyRender, mobileLabel} = item;
                const label = (
                    <div>
                        {mobileLabel}
                        <img src={ExternalLinkIcon} alt="" style={{marginLeft: 8, height: 11}}/>
                    </div>
                );
                mainMenu.push({
                    key: keyRender,
                    label: renderSolutionMobileMainLink(label, logo, {height: 15}),
                    labelHidden: !mobile,
                    externalLink: url
                });
            });
        };
        const renderSolutionsSubmenuItemList = list => {
            return list.map(listItemTranslationKey => {
                return (
                    <div className={css.listItem} key={listItemTranslationKey}>
                        {listItemTranslationKey}
                    </div>
                );
            });
        };
        const renderSolutionsSubmenuItem = props => {
            const {page, logo, logoStyle, logoAlt, description, list, background = false} = props;
            return (
                <Link to={getLink([page])} className={css.solutionsSubmenuLink}>
                    {background &&
                        <img
                            className={css.background}
                            src={BackgroundImg}
                            alt={""}
                        />
                    }
                    <div className={css.solutionsSubmenuLogo}>
                        <img src={logo} alt={logoAlt} style={logoStyle}/>
                    </div>
                    <div className={css.solutionsSubmenuText}>
                        <div className={css.description}>{description}</div>
                        <div className={css.listWrapper}>{renderSolutionsSubmenuItemList(list)}</div>
                    </div>
                    <MainButton
                        label="Learn more"
                        isLink={false}
                        className={css.solutionsSubmenuButton}
                    />
                </Link>
            );
        };
        const renderSolutionsSubmenu = (setOpenedCallback) => {
            return (
                <div className={css.solutionsSubmenu}>
                    <div className={css.solutionsSubmenuContent}>
                        {renderSolutionsSubmenuItem({
                            page: routes.Solutions,
                            logo: DcodeLogo,
                            logoStyle: {height: 50},
                            logoAlt: "optiwaiplus logo logotype",
                            description: <>Visual Content Workflow <br/>Processing Automation</>,
                            list: ["Applying the power of Computer Vision", "Incredible multifunctional quality output"],
                            background: true
                        })}
                        {renderFastTrackDesktopMenu()}
                    </div>
                    <div className={css.solutionsSubmenuOverlay} onClick={() => setOpenedCallback(false)}/>
                </div>
            );
        };
        if (!mobile) {
            mainMenu.push({
                key: 'menu_solutions_dropdown',
                isDropdown: true,
                label: "Solutions",
                setActiveForSubpages: [[routes.Solutions]],
                renderCustomSubmenuFn: renderSolutionsSubmenu
            });
        }
        addFastTrackItemsToMobileMenu();
    */

// ------------------ sign-in button --------------------


    /*const renderSignInHeader = () => {
        return (
            <div className={css.signInHeader}>
                <img src={SignInHeaderImg} alt=""/>
            </div>
        );
    };*/

    const renderApplicationSignInLogo = (application, logo, logoAlt) => {
        return (
            <SignUpButton application={application}>
                <div className={css.signInLogoWrapper}>
                    <img src={logo} alt={logoAlt}/>
                </div>
            </SignUpButton>
        );
    };

    const menuSignInDropdownItems = [];

    /*if (!mobile) {
        menuSignInDropdownItems.push({
            key: 'menu_sign_in_header',
            customItemRender: renderSignInHeader()
        });
    }*/

    menuSignInDropdownItems.push({
        key: 'menu_timberid_sign_in_logo',
        customItemRender: renderApplicationSignInLogo('TimberID', TimberIdLogo, "timberid logo logotype")
    });

    /*const handleSignInOtherOpenClick = () => {
        setSignInOtherOpen(!signInOtherOpen);
    };

    const renderSignInOtherSectionHeader = () => {
        const headerText = 'Other';
        if (mobile) {
            return (
                <div className={css.signInOthersMobileHeader}>{headerText}</div>
            );
        }
        return (
            <div className={css.signInOthersHeader} onClick={() => handleSignInOtherOpenClick()}>
                <div className={css.signInOthersHeaderContent}>
                    {headerText}
                    <MenuDropdownArrows opened={signInOtherOpen}/>
                </div>
            </div>
        );
    };

    menuSignInDropdownItems.push({
        key: 'menu_sign_in_other',
        customItemRender: (
            <div>
                {renderSignInOtherSectionHeader()}
                {(mobile || signInOtherOpen) &&
                    <div className={css.signInOtherSectionContent}>
                        <SignUpButton application={'Optiwai'}>
                            <div className={css.signInOthersListItem}>
                                <div className={css.signInOthersListText}>OptiwAI</div>
                                <img src={ExternalLinkIcon} className={css.signInOthersListIcon} alt=""/>
                            </div>
                        </SignUpButton>
                    </div>
                }
            </div>
        )
    });*/

    if (site.siteMetadata.webAppSignInEnabled) {
        mainMenu.push({
            key: 'menu_sign_in_dropdown',
            isDropdown: true,
            label: "Sign in",
            submenu: [...menuSignInDropdownItems],
            borderedAndGreen: !mobile
        });
    }

// --------------------------------------

    return (
        <>
            <div className={css.ueMobile}>
                <img src={UE} alt=""/>
            </div>
            <header className={[css.header, css.headerTight, mobileClass, openClass].join(' ')}>

                <div className={css.overlay} onClick={() => toggleMenu()}/>

                <div className={css.hamburger} onClick={() => toggleMenu()}>
                    <div className={css.line}/>
                    <div className={css.line}/>
                    <div className={css.line}/>
                </div>

                <div className={css.wrapper}>

                    <div className={css.logoWrapper}>

                        <DeeplaiButton height={'42px'} version={'white'}/>
                        <div className={css.closeButton} onClick={() => toggleMenu()}>
                            <div className={css.closeButtonInner}/>
                        </div>
                    </div>
                    <nav>
                        {renderMenu(mainMenu, 0)}
                    </nav>
                    <div className={css.ueDesktop}>
                        <img src={UE} alt=""/>
                    </div>

                </div>
            </header>
        </>
    );
};

export default Header;
