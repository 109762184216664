import {graphql, useStaticQuery} from "gatsby";
import React from 'react';

const SignUpButton = props => {
    const {site} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            optiplusWebAppUrl
            timberIdWebAppUrl
          }
        }
      }
    `
    );

    const {
        application = 'Optiwai',
    } = props;

    const prepareParams = () => {
        switch (application) {
            case 'OptiwaiPlus':
                return {
                    href: `${site.siteMetadata.optiplusWebAppUrl}/auth/register`,
                    color: 'green'
                };
            case 'TimberID':
                return {
                    href: `${site.siteMetadata.timberIdWebAppUrl}`,
                    color: 'green'
                };
            default:
                return {
                    href: site.siteMetadata.siteUrl,
                    color: 'green'
                };
        }
    };

    const params = prepareParams();

    return (
        <a
            href={params.href}
            target="_blank"
            rel="noopener noreferrer"
        >
            {props.children}
        </a>
    );
};

export default SignUpButton;
