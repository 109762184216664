import React, {useEffect} from 'react';
import css from './layout.module.scss';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import {getLink, getRoutes, legalDocuments} from '../shared/routes';
import Header from './Header/Header';
import '../../styles/reset.css';
import '../../locales/i18n';
import '../../styles/_global.scss';
import CompanyLogo from "../../images/logos/Navbar_Logo_Slogan.svg";
import CookieBanner from "./CookieBanner";

const Layout = ({ children }) => {
  // https://github.com/cferdinandi/smooth-scroll
  const addSmoothScrolling = () => {
    require("smooth-scroll")('a[href*="#"]', {
      speed: 300,
      topOnEmptyHash: false,
      offset: function (anchor, toggle) {
        const menuLevel1Active = document.querySelectorAll('[data-menu-active="true"]');
        if (menuLevel1Active.length) {
          return menuLevel1Active[0].offsetHeight;
        }

        return 0;
	  }
      // speedAsDuration: false
    });
  };

  useEffect(() => {
    addSmoothScrolling();
  },[]);

  const routes = getRoutes();
  const renderPasswordPolicyLink = () => {
    const link = getLink([routes.PrivacyPolicy]);
    return <Link to={link}>Learn more</Link>;
  };

  const cookieButtonStyle = {
    border: '2px solid var(--mainGreenColor)',
    borderRadius: '100px',
    background: 'var(--mainGreenColor)',
    fontSize: '.7rem',
    fontWeight: 700,
    letterSpacing: '1.08px',
    color: 'var(--mainWhiteColor)',
    padding: '4px 16px',
  };

  const linkToHome = getLink([routes.home]);


  return (
    <>
      <div className={css.mobileLogoWrapper}>
        <Link to={linkToHome} className={css.mobileLogoLink}>
          <img src={CompanyLogo} className={css.CompanyLogo} alt=""/>
        </Link>
      </div>

      <Header />

      <main className="main">
        <div className={css.mainTopPlaceholder} />
        {children}
      </main>

      <div className={css.fixedItemsWrapper}>
        <CookieBanner renderPasswordPolicyLink={renderPasswordPolicyLink}/>
      </div>

    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
