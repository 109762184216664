import React from "react";
import css from './headerMobileMenuSeparator.module.scss';

// TODO finish it

const HeaderMobileMenuSeparator = props => {
    const { text} = props;

    if (!text) {
        return null;
    }

    return (
        <div className={css.wrapper}>
            {text}
        </div>
    )
}

export default HeaderMobileMenuSeparator;