import React from "react";
import css from './submenu.module.scss';

const Submenu = props => {
    const {
        active = false,
        children,
        menuLevel = 0,
        mobile = false
    } = props;

    const activeClass = active ? css.active : null;
    const mobileClass = mobile ? css.mobile : '';

    return (
        <div className={[css.wrapper, mobileClass, activeClass].join(' ')} data-menu-level={menuLevel} data-menu-active={active}>
            <div className={css.wrapperInner}>{children}</div>
        </div>
    )
}

export default Submenu;