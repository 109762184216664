import React from "react";
import css from './headerLink.module.scss';
import { Link } from 'gatsby';
import SubpageLink from "../InternalLinks/SubpageLink";
import DropdownLink from "../InternalLinks/DropdownLink";
import MenuDropdownArrows from "../MenuDropdownArrows/MenuDropdownArrows";
import HeaderMobileMenuSeparator from "../Header/HeaderMobileMenuSeparator";

const HeaderLink = props => {
  const {
    subpage,
    externalLink = false,
    customItemRender,
    borderedAndGreen,
    // section,
    // currentPage,
    isDropdown = false,
    isHeaderMobileMenuSeparator = false,
    text = '',
    textHidden = false,
    separator = false,
    mobile = false,
    onClick = () => {},
    submenu = [],
    renderCustomSubmenuFn,
    menuLevel = 0,
    setActiveForSubpages = []
  } = props;
  const separatorClass = separator ? css.separator : '';
  const mobileClass = mobile ? css.mobile : '';
  const textHiddenClass = textHidden ? css.textHidden : '';
  const borderedAndGreenClass = borderedAndGreen ? css.borderedAndGreen : '';

  const handleClick = () => {
    onClick();
    if (!isDropdown) {
      window.scrollTo(0, 0);
    }
  };

  const wrapperClasses = [css.wrapper, textHiddenClass, borderedAndGreenClass, separatorClass, mobileClass].join(' ');

  const renderLinkText = (text) => {
    return text;
  };

  const renderLinkInner = (renderLinkTextFc = (text) => renderLinkText(text)) => {
    return (
        <div className={css.link} onClick={() => handleClick()}>
          <div className={css.logoLinkWrapper}>
            <div className={css.textWrapper}>
              <div className={css.text}>{renderLinkTextFc(text)}</div>
            </div>
          </div>
        </div>
    )
  };

  if (customItemRender) {
    return (
      <div className={wrapperClasses} data-menu-level={menuLevel}>
        <div className={css.dropdownCustomRenderedItem}>
          {customItemRender}
        </div>
      </div>
    )
  }

  if (externalLink) {
    return (
      <div className={wrapperClasses} data-menu-level={menuLevel}>
        <a href={externalLink} target="_blank">
          <span>
            {renderLinkInner()}
          </span>
        </a>
      </div>
    )
  }

  if (subpage) {
    return (
      <div className={wrapperClasses} data-menu-level={menuLevel}>
        <SubpageLink subpage={subpage} submenu={submenu} menuLevel={menuLevel} mobile={mobile}>
          {renderLinkInner()}
        </SubpageLink>
      </div>
    )
  }

  if (isDropdown) {
    return (
      <div className={[wrapperClasses, css.dropdownWrapper].join(' ')} data-menu-level={menuLevel}>
        <DropdownLink
            submenu={submenu}
            renderCustomSubmenuFn={renderCustomSubmenuFn}
            mobile={mobile}
            setActiveForSubpages={setActiveForSubpages}
            renderLinkInner={renderLinkInner}
        />
      </div>
    )
  }

  if (isHeaderMobileMenuSeparator) {
    return <HeaderMobileMenuSeparator {...props} />
  }

  return null;
};

export default HeaderLink;
